export default palette => ({
  root: {
    backgroundColor: palette.background.paper,
    boxShadow: 'none',
    borderRight: `1px solid ${palette.border}`,
    marginRight: 12,
    maxWidth: 255,
    overflow: 'hidden',
  },
  modal: {
    width: 240,
  },
  paperAnchorLeft: {
    height: '100% !important',
  },
})
