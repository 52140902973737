const white = '#FFFFFF'
const black = '#000000'

export default {
  type: 'light',
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },
  primary: {
    contrastText: white,
    main: '#3332ee',
    light: '#5b5bf1',
    dark: '#2323a6',
  },
  secondary: {
    contrastText: '#2b50ed',
    main: white,
    light: '',
    dark: '#37248F',
  },
  success: {
    contrastText: black,
    main: '#4caf50',
    light: '#6fbf73',
    dark: '#357a38',
  },
  info: {
    contrastText: white,
    main: '#2196f3',
    light: '#4dabf5',
    dark: '#1769aa',
  },
  warning: {
    contrastText: white,
    main: '#ff9800',
    light: '#ffac33',
    dark: '#b26a00',
  },
  danger: {
    contrastText: white,
    main: '#f6685e',
    light: '#f73378',
    dark: '#aa2e25',
  },
  text: {
    primary: '#445162',
    secondary: '#66788A',
    disabled: '#A6B1BB',
  },
  background: {
    default: '#f4f6f8',
    dark: 'rgba(0, 0, 0, 0.04)',
    paper: white,
  },
  border: 'rgba(0, 0, 0, .12)',
  divider: 'rgba(0, 0, 0, .12)',
}
