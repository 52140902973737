export default {
  apiKey: 'AIzaSyDE7rNv-0WzZwpi_kGLh5dp0g4XGj_ivaQ',
  authDomain: 'givepact-app.firebaseapp.com',
  databaseURL: 'https://givepact-app.firebaseio.com',
  projectId: 'givepact-app',
  storageBucket: 'givepact-app.appspot.com',
  messagingSenderId: '695466590733',
  appId: '1:695466590733:web:c3c41416dde40580b40b44',
  measurementId: 'G-YQLZT4LRGW',
}
