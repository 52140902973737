export default palette => ({
  root: {
    color: palette.text.primary,
    height: '56px',
    '&$selected': {
      backgroundColor: palette.background.default,
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default,
      },
    },
  },
})
