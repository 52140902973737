import React from 'react'
import { Admin, Resource } from 'react-admin'
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from 'react-admin-firebase'

import example from './resources/example'

import themeReducer from './utils/themeReducer'
import CustomLayout from './components/layout'

import config from './firebaseConfig'

const options = {
  logging: true,
  rootRef: 'givepact/admin',
}
const dataProvider = FirebaseDataProvider(config, options)
const authProvider = FirebaseAuthProvider(config, options)

const resources = [example]

export default () => (
  <Admin
    title="GivePact Admin"
    customReducers={{ theme: themeReducer }}
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={CustomLayout}
  >
    {resources.map(resource => (
      <Resource
        key={`resource-${resource.name}`}
        icon={resource.icon}
        name={resource.name}
        list={resource.list}
        create={resource.create}
        edit={resource.edit}
        options={resource.options}
      />
    ))}
  </Admin>
)
