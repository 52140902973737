import React from 'react'
import { AppBar, UserMenu } from 'react-admin'
import { useSelector, useDispatch } from 'react-redux'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { Sun, Moon, User } from 'react-feather'

import { changeTheme } from '../../utils/actions'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: '700',
  },
  spacer: {
    flex: 1,
  },
})

export default props => {
  const classes = useStyles()
  const theme = useSelector(state => state.theme)
  const dispatch = useDispatch()
  return (
    <AppBar {...props} userMenu={<UserMenu icon={<User />} />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <IconButton
        aria-label="change-theme"
        color="inherit"
        onClick={() => {
          const newTheme = theme === 'light' ? 'dark' : 'light'
          return dispatch(changeTheme(newTheme))
        }}
      >
        {theme === 'light' ? <Moon /> : <Sun />}
      </IconButton>
    </AppBar>
  )
}
