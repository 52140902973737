import { fade } from '@material-ui/core'
import MuiButton from './MuiButton'
import MuiFab from './MuiFab'
import MuiFilledInput from './MuiFilledInput'
import MuiIconButton from './MuiIconButton'
import MuiInputBase from './MuiInputBase'
import MuiLinearProgress from './MuiLinearProgress'
import MuiMenuItem from './MuiMenuItem'
import MuiListItemIcon from './MuiListItemIcon'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiSvgIcon from './MuiSvgIcon'
import MuiTab from './MuiTab'
import MuiTableCell from './MuiTableCell'
import MuiTableRow from './MuiTableRow'
import MuiTabs from './MuiTabs'
import MuiAppBar from './MuiAppBar'
import MuiCard from './MuiCard'
import MuiCardContent from './MuiCardContent'
import MuiDrawer from './MuiDrawer'
import MuiFormControl from './MuiFormControl'
import RaAutocompleteArrayInput from './RaAutocompleteArrayInput'
import RaRichTextInput from './RaRichTextInput'
import RaBulkActionsToolbar from './RaBulkActionsToolbar'

export default palette => ({
  MuiFormControl,
  MuiButton: MuiButton(palette),
  MuiIconButton: MuiIconButton(palette),
  MuiFab: MuiFab(palette),
  MuiFilledInput: MuiFilledInput(palette),
  MuiInputBase: MuiInputBase(palette),
  MuiLinearProgress: MuiLinearProgress(palette),
  MuiMenuItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab: MuiTab(palette),
  MuiTableCell: MuiTableCell(palette),
  MuiTableRow: MuiTableRow(palette),
  MuiTabs,
  MuiAppBar: MuiAppBar(palette),
  MuiCard: MuiCard(palette),
  MuiCardContent,
  MuiDrawer: MuiDrawer(palette),
  RaAutocompleteArrayInput: RaAutocompleteArrayInput(palette),
  RaRichTextInput: RaRichTextInput(palette),
  RaBulkActionsToolbar: RaBulkActionsToolbar(palette),
  MuiChip: {
    deleteIcon: {
      color: fade(palette.primary.contrastText, 0.3),
      '&:hover': {
        color: fade(palette.primary.contrastText, 0.7),
      },
    },
  },
  RaResettableTextField: {
    root: {
      width: 363,
    },
  },
  RaEdit: {
    card: {
      height: 'fit-content',
      width: '15em',
    },
  },
  RaCreate: {
    card: {
      height: 'fit-content',
    },
  },
  RaFormInput: {
    input: {
      width: 363,
    },
  },
  RaMenu: {
    main: {
      padding: 8,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  RaMenuItemLink: {
    root: {
      borderRadius: 6,
      paddingTop: 8,
      paddingBottom: 8,
      fontWeight: 400,
    },
    active: {
      fontWeight: 600,
      color: palette.primary.main,
    },
  },
  MuiListItem: {
    root: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    active: {
      fontWeight: 600,
      color: palette.primary.main,
    },
  },
  RaSidebar: {
    drawerPaper: {
      minWidth: 76,
    },
  },
  RaAppBar: {
    menuButton: {
      padding: 12,
    },
  },
  RaList: {
    content: {
      overflow: 'overlay',
    },
  },
  RaListToolbar: {
    toolbar: {
      backgroundColor: `${palette.background.default} !important`,
    },
  },
  RaToolbar: {
    toolbar: {
      backgroundColor: palette.background.paper,
      borderTop: `1px solid ${palette.border}`,
    },
  },
  RaAutocompleteSuggestionList: {
    suggestionsPaper: {
      border: `1px solid ${palette.border}`,
      borderRadius: 6,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  RaAutocompleteSuggestionItem: {
    root: {
      borderRadius: 0,
    },
  },
  Layout: {
    appFrame: {
      height: '100vh',
    },
    contentWithSidebar: {
      height: '100%',
    },
  },
  MUIRichTextEditor: {
    root: {
      backgroundColor: palette.background.paper,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 8,
    },
    editor: {
      backgroundColor: palette.background.paper,
      padding: 8,
      minHeight: 400,
      height: '100%',
      maxHeight: 400,
      overflow: 'auto',
    },
    toolbar: {
      borderBottom: `1px solid ${palette.border}`,
      backgroundColor: palette.background.paper,
      paddingBottom: 8,
    },
    placeHolder: {
      backgroundColor: palette.background.paper,
      paddingLeft: 8,
      width: 'inherit',
      top: 64,
    },
    inlineToolbar: {
      backgroundColor: palette.background.paper,
      border: `1px solid ${palette.border}`,
    },
  },
})
