import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export default props => (
  <Create {...props}>
    <SimpleForm variant="outlined">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)
