export default palette => ({
  '@global': {
    '& .ql-snow .ql-picker': {
      color: palette.text.primary,
    },
    '.ra-rich-text-input': {
      marginBottom: 8,
      borderTop: '1px solid rgba(0, 0, 0, .23)',
      '& .outlined .ql-editor': {
        backgroundColor: palette.background.paper,
      },
      '& label': {
        padding: '0 4px 0 8px',
        top: -8,
        left: 8,
        width: 'fit-content',
        background: palette.background.paper,
      },
      '& .ql-toolbar.ql-snow': {
        paddingLeft: 4,
      },

      '& .ql-editor': {
        color: palette.text.main,
        borderRadius: 6,
        padding: 12,
        border: '1px solid rgba(0, 0, 0, .23)',
        minHeight: 300,
        left: 1,
        right: 1,
        top: 1,
        bottom: 1,
        '& p, span': {
          color: 'inherit !important',
        },
        '& p:hover, span:hover, a:hover, ul:hover, li:hover, ol:hover, strong:hover, em:hover, u:hover': {
          border: 0,
        },
        '& h1:hover, h2:hover, h3:hover, h4:hover': {
          border: 0,
        },
        '&::before': {
          content: 'none',
        },
        '&::after': {
          content: 'none',
        },
      },
      '& .ql-container': {
        '& :hover': {
          backgroundColor: palette.background.dark,
          border: '1px solid rgba(0, 0, 0, 1)',
        },
        '& :focus': {
          border: `2px solid ${palette.primary.main}`,
          padding: 11,
        },
      },
    },
  },
})
