import ExampleIcon from '@material-ui/icons/HelpOutlineOutlined'
import List from './List'
import Edit from './Edit'
import Create from './Create'

export default {
  icon: ExampleIcon,
  name: 'examples',
  list: List,
  edit: Edit,
  create: Create,
  options: {
    label: 'Example',
  },
}
