// Material helpers
import { createMuiTheme } from '@material-ui/core'

import typography from '../common/typography'
import overrides from '../common/overrides'
import shadows from '../common/shadows'
import palette from './palette'

const theme = createMuiTheme({
  palette,
  typography: typography(palette),
  overrides: overrides(palette),
  shadows,
  shape: {
    borderRadius: 6,
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export default theme
