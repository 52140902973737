export default palette => ({
  root: {
    borderBottom: `1px solid ${palette.border}`,
    padding: '14px 40px 14px 24px',
  },
  head: {
    fontSize: '14px',
    color: palette.text.primary,
  },
})
