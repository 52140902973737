export default palette => ({
  container: {
    marginTop: '8px !important',
    marginBottom: 4,
  },
  chip: {
    background: palette.primary.main,
    color: palette.primary.contrastText,
    marginTop: 8,
    '&:focus': {
      backgroundColor: palette.primary.dark,
    },
  },
})
