import React from 'react'
import { useSelector } from 'react-redux'
import { Layout } from 'react-admin'
import makeStyles from '@material-ui/core/styles/makeStyles'

import darkTheme from '../../themes/darkTheme'
import lightTheme from '../../themes/lightTheme'
import AppBar from './AppBar'

const useStyles = makeStyles({
  fullHeightLayout: {
    '& > div:first-of-type': {
      minHeight: 'calc(100vh - 48px)',
    },
  },
})

const CustomLayout = props => {
  const styles = useStyles()
  const theme = useSelector(state =>
    state.theme === 'dark' ? darkTheme : lightTheme
  )
  return (
    <Layout
      {...props}
      appBar={AppBar}
      theme={theme}
      className={styles.fullHeightLayout}
    />
  )
}

export default CustomLayout
