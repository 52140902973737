export default palette => ({
  colorSecondary: {
    backgroundColor:
      palette.type === 'dark' ? palette.background.paper : palette.primary.main,
    color:
      palette.type === 'dark'
        ? palette.text.primary
        : palette.primary.contrastText,
    boxShadow: 'none',
    borderBottom:
      palette.type === 'dark' ? `1px solid ${palette.border}` : 'none',
  },
})
