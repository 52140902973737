const white = '#FFFFFF'
const black = '#000000'

export default {
  type: 'dark',
  common: {
    black,
    white,
  },
  primary: {
    contrastText: white,
    main: '#42a5f5',
    light: '#67b7f7',
    dark: '#2e73ab',
  },
  secondary: {
    contrastText: black,
    main: '#00e676',
    light: '#33eb91',
    dark: '#00a152',
  },
  success: {
    contrastText: black,
    main: '#4caf50',
    light: '#6fbf73',
    dark: '#357a38',
  },
  info: {
    contrastText: white,
    main: '#2196f3',
    light: '#4dabf5',
    dark: '#1769aa',
  },
  warning: {
    contrastText: white,
    main: '#ff9800',
    light: '#ffac33',
    dark: '#b26a00',
  },
  danger: {
    contrastText: white,
    main: '#f6685e',
    light: '#f73378',
    dark: '#aa2e25',
  },
  text: {
    primary: '#ffffff',
    secondary: '#999da7',
    disabled: '#A6B1BB',
  },
  background: {
    default: '#1c2026',
    dark: 'rgba(255, 255, 255, 0.04)',
    paper: '#282c34',
  },
  border: 'rgba(255, 255, 255, .12)',
  divider: 'rgba(255, 255, 255, .12)',
}
