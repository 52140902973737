import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

export default props => (
  <Edit {...props}>
    <SimpleForm variant="outlined">
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)
